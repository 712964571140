import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: 500;
  color: var(--secondary-text);
  width: 130px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    cursor: pointer;
  }
  :hover {
    background-color: #ffffff;
    color: #000000;
    cursor: pointer;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    cursor: pointer;
  }
  :hover {
    background-color: #ffffff;
    color: #000000;
    cursor: pointer;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Choose amount to mint (max. 20)`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  
  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your Bilious Boy Scout...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Oops, something went wrong... Please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congrats! The ${CONFIG.NFT_NAME} NFT is yours! You can go to Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  //Calculate percentage
  var percentage=(Number(data.totalSupply)/CONFIG.MAX_SUPPLY) * 100;

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ 
          padding: "0px 20px 0px 20px", 
          backgroundColor: "var(--primary)" 
        }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpg" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />

        <ResponsiveWrapper flex={1} style={{ padding: 0}}>
          <s.Container
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "#13080a",
              padding: 30,
              borderRadius: 5,
              margin: "30px auto",
              height: "100%",
              border: "1px solid #b7a399",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
           
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The NFT sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>

                <s.TextTitle
                  style={{ 
                    textAlign: "center",
                    fontSize: 48,
                    fontWeight: 900,
                    lineHeight: 0.8,
                    margin: "0px auto 20px auto",
                    color: "var(--primary-text)" 
                  }}
                >
                  <text style={{ color: "var(--secondary", fontSize: 16, fontWeight: 300 }}>MINT YOUR</text>
                  <s.SpacerXSmall />
                  <text>Bilious Boy Scout</text>
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextTitle
                  style={{ 
                    textAlign: "center",
                    fontSize: 28,
                    fontWeight: 900,
                    margin: 0,
                    width: 400,
                    color: "var(--secondary)" 
                  }}
                >
                  {CONFIG.DISPLAY_COST}{" "} {CONFIG.NETWORK.SYMBOL} <text style={{ color: "var(--primary-text", fontSize: 18, fontWeight: 300 }}>  /  piece </text>
                </s.TextTitle>


                {data.totalSupply !== 0 &&
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    width: 400,
                    fontWeight: 200,
                    color: "var(--accent-text)",
                  }}
                >
                  Sold {data.totalSupply} / {CONFIG.MAX_SUPPLY} ({percentage}%)
                </s.TextTitle>
                }     


                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontWeight: 200,
                        fontSize: 15
                      }}
                    >
                      Please connect your MetaMask wallet to the {CONFIG.NETWORK.NAME} network to mint. 
                      Click on the MetaMask chrome extension if you don't see an automatic popup.
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontWeight: 200,
                        fontSize: 15
                      }}
                    >
                      A complete step-by-step guide on how to mint your Bilious Boy Scouts NFT <a href="https://medium.com/@BiliousBoyScouts/how-to-set-up-your-metamask-wallet-for-the-polygon-network-add-matic-tokens-and-use-it-to-purchase-c67acf3e0c31" target="_blank" style={{color: "#9377e6"}}>can be found here</a>.
                      </s.TextDescription>
                    <s.SpacerMedium />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        fontWeight: 300,
                        fontSize: 15,
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4, border: "1px solid #ffffff" }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          fontSize: 22,
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledRoundButton
                        style={{ lineHeight: 0.4, border: "1px solid #ffffff" }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "PROCESSING..." : "BUY NOW"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />

            <s.TextDescription
              style={{
                textAlign: "center",
                fontSize: 14,
                color: "var(--primary-text)",
              }}
            >
              <textTitle>Contract address: </textTitle>
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK} style={{color: "var(--primary-text)", textDecoration: "underline"}}>
                 {truncate(CONFIG.CONTRACT_ADDRESS, 20)}
              </StyledLink>
            </s.TextDescription>
            
          </s.Container>
          <s.SpacerSmall />

        </ResponsiveWrapper>

        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              fontSize: 15,
              color: "var(--primary-text)",
              margin: "10px auto"
            }}
          >
            
          </s.TextDescription>
          <s.SpacerSmall />
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
